/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// previne bug de cor do clearInput
.input-clear-icon.sc-ion-input-md,
.input-clear-icon.sc-ion-input-ios {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><polygon fill='white' points='405,136.798 375.202,107 256,226.202 136.798,107 107,136.798 226.202,256 107,375.202 136.798,405 256,285.798 375.202,405 405,375.202 285.798,256'/></svg>") !important;
  width: 30px;
  height: 30px;
  background-size: 22px;
}

.alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
  background-color: white;
}

.action-sheet-group.sc-ion-action-sheet-md {
  background-color: white;
  color: black;
}

.action-sheet-button.ion-activatable.ion-focusable.sc-ion-action-sheet-md {
  border-bottom: 1px solid rgb(216, 216, 216);
}

.action-sheet-title.sc-ion-action-sheet-md {
  border-bottom: 1px solid rgb(216, 216, 216);
}

.infinite-scroll-content-md .infinite-loading-text {
  color: white;
}

.ion-spinner {
  --color: white;
}

.gmnoprint a, .gmnoprint span, .gmnoprint div  {
  display:none;
}
